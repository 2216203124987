import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Jumbotron,
  Navbar
} from "react-bootstrap";
import axios from "axios";
import Layout from "../components/Layout/LayoutSecond";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import VideoModal from "../components/VideoModal/VideoModal";
import { P, H1, H2, H4, H6, Span } from "../components/Typography/Typography";
import raduAmarie from "../images/radu-amarie.jpg";
import danielIlinca from "../images/daniel-ilinca.jpg";
import gabrielaAmarie from "../images/gabriela-amarie.jpg";
import dorinMinea from "../images/dorin-minea.jpg";
import violetaGaina from "../images/violeta-gaina.jpg";
import raducuRoman from "../images/raducu-roman.jpg";
import ralucaPoiana from "../images/raluca-poiana.jpg";
import mihaiMaruseac from "../images/mihai-maruseac.jpg";
import caterinaApostol from "../images/caterina-apostol.jpg";
import youtube from "../images/sf-youtube.png";
import fbGroup from "../images/sf-fb-group.png";
import talksLogo from "../images/sf-talks-logo.png";
import logo from "../images/logo.svg";
import fb from "../images/fb-icon.png";
import group from "../images/fb-group.png";
import yt from "../images/youtube.png";

const videosPerPage = 6;
let arrayForHoldingVideos = [];

const CommunityPage = () => {
  const [videos, setVideos] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [videosToShow, setVideosToShow] = useState([]);
  const [next, setNext] = useState(6);

  const handleModal = (video, title, show) => {
    const modalVideos = showModal.filter(vid => vid.video !== video);
    setShowModal([{ video: video, title: title, show: show }, ...modalVideos]);
  };

  const loopWithSlice = (start, end) => {
    const slicedVideos = videos.slice(start, end);
    arrayForHoldingVideos = [...arrayForHoldingVideos, ...slicedVideos];
    setVideosToShow(arrayForHoldingVideos);
  };

  const handleShowMoreVideos = () => {
    loopWithSlice(next, next + videosPerPage);
    setNext(next + videosPerPage);
  };

  useEffect(() => {
    loopWithSlice(0, videosPerPage);
  }, [videos]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const YOUTUBE_PLAYLIST_ITEMS_API =
      "https://www.googleapis.com/youtube/v3/search";

    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?type=video&order=date&part=snippet,id&maxResults=200&channelId=UCC21lDEdmEIE4ha7F76_X2w&key=${process.env.GATSBY_YOUTUBE_API_KEY}`
      )
      .then(res => {
        const videos = res.data;
        const videosList = videos.items;
        setVideos(videosList);

        const openVideos = videosList.map(video => {
          return {
            video: video.id.videoId,
            title: video.snippet.title,
            show: false
          };
        });

        setShowModal(openVideos);
      });
  }, []);

  return (
    <PageTransition>
      <Layout>
        <SEO title="Community" />
        <Container>
          <Jumbotron as="header" className="sf-landing-page">
            <Row>
              <Navbar expand="lg" className="sf-navbar">
                <Navbar.Brand as={Link} to="/">
                  <Image
                    src={logo}
                    className="d-inline-block align-top"
                    alt="Softbinator Foundation"
                  />
                </Navbar.Brand>
              </Navbar>
            </Row>
            <Row>
              <Col lg={8}>
                <H1>
                  Join the largest <Span>developers community</Span> in Romania
                </H1>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <P>
                  Join our exciting tech community for weekly tech talks and a
                  great place where you can share or view what others are coding
                  / building.
                </P>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-start mt-3">
                <Form
                  className="get-notified large community"
                  action="https://softbinator.us2.list-manage.com/subscribe/post?u=a8f550154253f6574139ed3ae&amp;id=7d076fb650"
                  method="POST"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  inline
                >
                  <Form.Label htmlFor="email" srOnly>
                    Name
                  </Form.Label>
                  <Form.Control
                    className=""
                    id="mce-EMAIL"
                    name="EMAIL"
                    placeholder="Enter your e-mail address"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <Form.Control
                      className=""
                      name="b_a8f550154253f6574139ed3ae_7d076fb650"
                      tabIndex="-1"
                      defaultValue=""
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className=""
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  >
                    Join <Span>our community</Span>
                  </Button>
                </Form>
              </Col>
            </Row>
          </Jumbotron>
        </Container>

        <Section className="sf-who-we-are">
          <Container>
            <Row>
              <Col md={7}>
                <H2>Who we are</H2>
                <P>
                  Since 2012, we organize events to help programmers develop
                  tech skills, share opinions, showcase what they are working
                  on, and check out the epic things others are building.
                </P>
                <Row className="mt-5">
                  <Col md={6}>
                    <div className="youtube-channel d-flex justify-content-center align-items-center">
                      <Image src={youtube} alt="" />
                      <div>
                        <p>Softbinator Foundation</p>
                        <a
                          href="https://www.youtube.com/user/softbinator"
                          title="Subscribe to our videos"
                        >
                          Subscribe to our videos
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="fb-group d-flex justify-content-center align-items-center">
                      <Image src={fbGroup} alt="" />
                      <div>
                        <p>Talks by Softbinator</p>
                        <a
                          href="https://www.facebook.com/groups/talks.by.softbinator"
                          title="Join our Facebook Group"
                        >
                          Join our Facebook Group
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <iframe
                  name="f3d22f42d1fe25"
                  width="340px"
                  height="500px"
                  data-testid="fb:page Facebook Social Plugin"
                  title="fb:page Facebook Social Plugin"
                  frameBorder="0"
                  allowtransparency="true"
                  allowFullScreen={true}
                  scrolling="no"
                  allow="encrypted-media"
                  src="https://www.facebook.com/v3.2/plugins/page.php?adapt_container_width=true&amp;app_id=396061880449797&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df79e9297dde8e%26domain%3Dfoundation.softbinator.com%26origin%3Dhttps%253A%252F%252Ffoundation.softbinator.com%252Ff2e2671e0f08608%26relation%3Dparent.parent&amp;container_width=1110&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fsoftbinator%2F&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=events"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </Section>

        <Container>
          <Section className="sf-what-we-do secondary">
            <Container fluid>
              <Row>
                <Col>
                  <H2 className="mb-5">What we do</H2>
                </Col>
              </Row>

              <Container className="container-medium">
                <Row>
                  <Col md={4} className="d-flex justify-content-center">
                    <Image
                      className="img-fluid"
                      src={talksLogo}
                      alt="Softbinator Talks"
                    />
                  </Col>
                  <Col md={8}>
                    <P>
                      We created an event named “Talks” with purpose to gather
                      awesome people on a biweekly basis, to talk about what’s
                      new or interesting in IT. The concept was started in
                      February 2013, and since then, we couldn’t stop. Usually,
                      there are two speakers per event who, for 30 minutes talk
                      about their ideas, new gizmos or technologies, solutions
                      for common yet difficult issues, followed by a short Q&A
                      session.
                    </P>
                  </Col>
                </Row>
              </Container>
              <Row>
                <Col className="d-flex justify-content-center">
                  <ListGroup horizontal="lg" className="my-3">
                    <ListGroup.Item className="d-flex align-items-center rounded-0 sessions">
                      <P className="d-flex flex-column">
                        <Span>133</Span> talks sessions
                      </P>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex align-items-center rounded-0 speakers">
                      <P className="d-flex flex-column">
                        <Span>251</Span> speakers
                      </P>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex align-items-center rounded-0 workshops">
                      <P className="d-flex flex-column">
                        <Span>7</Span> workshops
                      </P>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex align-items-center rounded-0 hackathons">
                      <P className="d-flex flex-column">
                        <Span>2</Span> hackathons
                      </P>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Container>
          </Section>
        </Container>

        <Section className="sf-team">
          <Container>
            <Row>
              <Col>
                <H2>Our Team</H2>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <ListGroup className="team flex-wrap" horizontal>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={raduAmarie}
                      className="img-fluid"
                      alt="Radu Amarie"
                    />
                    <P>
                      Radu Amarie <Span>Co-President</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={danielIlinca}
                      className="img-fluid"
                      alt="Daniel Ilinca"
                    />
                    <P>
                      Daniel Ilinca <Span>Co-President</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={gabrielaAmarie}
                      className="img-fluid"
                      alt="Gabriela Amarie"
                    />
                    <P>
                      Gabriela Amarie <Span>Co-Founder</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={dorinMinea}
                      className="img-fluid"
                      alt="Dorin Minea"
                    />
                    <P>
                      Dorin Minea <Span>Executive Director</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={violetaGaina}
                      className="img-fluid"
                      alt="Violeta Gaina"
                    />
                    <P>
                      Violeta Gaină <Span>Project Manager Bucharest</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={raducuRoman}
                      className="img-fluid"
                      alt="Raducu Roman"
                    />
                    <P>
                      Răducu Roman <Span>Project Manager Constanța</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={ralucaPoiana}
                      className="img-fluid"
                      alt="Raluca Poiana"
                    />
                    <P>
                      Raluca Poiană <Span>Grafic Designer</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={mihaiMaruseac}
                      className="img-fluid"
                      alt="Mihai Maruseac"
                    />
                    <P>
                      Mihai Maruseac <Span>Ambasador</Span>
                    </P>
                  </ListGroup.Item>
                  <ListGroup.Item className="col-6 col-sm-4 col-md-2">
                    <Image
                      src={caterinaApostol}
                      className="img-fluid"
                      alt="Caterina Apostol"
                    />
                    <P>
                      Caterina Apostol <Span>Social Media Manager</Span>
                    </P>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section className="sf-tech-talks">
          <Container>
            <Row>
              <Col>
                <H2>Our tech talks</H2>
                <P>
                  We created an event named “Talks” with purpose to gather
                  awesome people on a biweekly basis, to talk about what’s new
                  or interesting in IT. The concept was started in February
                  2013, and since then, we couldn’t stop. Usually, there are two
                  speakers per event who, for 30 minutes talk about their ideas,
                  new gizmos or technologies, solutions for common yet difficult
                  issues, followed by a short Q&A session.
                </P>
              </Col>
            </Row>
            <Row>
              {videosToShow.map(({ id, snippet = {} }, key) => {
                const { title, description, thumbnails = {} } = snippet;
                const { high } = thumbnails;
                const { videoId } = id;

                const formattedTitle = title.replace("&#39;", "'").replace("&amp;", '&');
                return (
                  <Col className="mt-4" md={4} sm={6} key={key}>
                    <span
                      role="link"
                      className="video"
                      tabIndex={0}
                      onClick={() => handleModal(videoId, title, true)}
                      onKeyDown={() => handleModal(videoId, title, true)}
                    >
                      <Image
                        className="img-fluid"
                        width={high.width}
                        height={high.height}
                        src={high.url}
                        alt={title}
                      />
                    </span>
                    <span className="title">{formattedTitle}</span>
                    <span className="description">{description}</span>
                  </Col>
                );
              })}

              {showModal.map(video => {
                const formattedTitle = video.title.replace("&#39;", "'").replace("&amp;", '&');

                return (
                  <VideoModal
                    key={video.video}
                    showModal={video.show}
                    handleModal={() =>
                      handleModal(video.video, video.title, false)
                    }
                    video={video.video}
                    title={formattedTitle}
                  />
                );
              })}
            </Row>
            <Row>
              <Col className="load-more d-flex justify-content-center">
                <button onClick={handleShowMoreVideos}>Show more</button>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section className="sf-join-community">
          <Container>
            <Row>
              <Col lg={8} className="chat-with-text">
                <H4>Join the largest developers community in Romania</H4>
                <H6>
                  Join our exciting tech community for weekly tech talks and a
                  great place where you can share or view what others are
                  coding/building.
                </H6>

                <Row>
                  <Col className="d-flex justify-content-start mt-3">
                    <Form
                      className="get-notified large community"
                      action="https://softbinator.us2.list-manage.com/subscribe/post?u=a8f550154253f6574139ed3ae&amp;id=7d076fb650"
                      method="POST"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      target="_blank"
                      inline
                    >
                      <Form.Label htmlFor="email" srOnly>
                        Name
                      </Form.Label>
                      <Form.Control
                        className=""
                        id="mce-EMAIL"
                        name="EMAIL"
                        placeholder="Enter your e-mail address"
                        required
                      />
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                      >
                        <Form.Control
                          className=""
                          name="b_a8f550154253f6574139ed3ae_7d076fb650"
                          tabIndex="-1"
                          defaultValue=""
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        className=""
                        name="subscribe"
                        id="mc-embedded-subscribe"
                      >
                        Join <Span>our community</Span>
                      </Button>
                    </Form>
                  </Col>
                </Row>

                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/softbinator/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={fb} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/groups/talks.by.softbinator"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={group} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/softbinator"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={yt} alt="" />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    </PageTransition>
  );
};

export default CommunityPage;
