import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";

const VideoModal = props => {
  return (
    <>
      <Modal
        dialogClassName="video"
        show={props.showModal}
        onHide={props.handleModal}
        backdrop="static"
        video={props.video}
        title={props.title}
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="video-wrapper">
                  <iframe
                    title="video"
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${props.video}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoModal;
