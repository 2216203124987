import React from "react";
import PropTypes from "prop-types";
import "../../styles/styles.scss";

const LayoutSecond = ({ children }) => {
  return (
    <>
      <main>{children}</main>
    </>
  );
};

LayoutSecond.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutSecond;
